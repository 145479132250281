import React, { useState } from "react";
import Modal from "react-modal";
import albums from "../albums.json";
import PhotoGallery from "./PhotoGallery";
import "./PhotoGallery.css";

Modal.setAppElement("#root");

const PhotoAlbum = () => {
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [albumToDisplay, setAlbumToDisplay] = useState(false);

  const openAlbum = (album) => {
    setShowNewComponent(true);
    setAlbumToDisplay(album);
  };
  // console.log('albums: ', albums);
  return (
    <div>
      <img src={"aquajets.jpg"} alt="Aqua Jets Logo" />
      <h1>Aqua Jets Photos</h1>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {albums.map((album) => (
          <img
            key={`Album-${album.id}`}
            src={album.thumbnail}
            alt={`Album-${album.title}`}
            style={{ width: "200px", margin: "10px" }}
            className="thumbnail"
            onClick={() => openAlbum(album)}
          />
        ))}
      </div>
      {showNewComponent && <PhotoGallery album={albumToDisplay} />}
    </div>
  );
};

export default PhotoAlbum;
